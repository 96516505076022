<template>

  <div ref="referenceSignListRef" class="reference-sign-list">

    <div class="reference-sign-header">
      <div class="left-aligned-stuff">
        <h2>{{ $t('referenceNumber') }}</h2>
        <button class="apply-changes-button icon-button" :title="$t('applyReferenceSigns')"
                v-show="showApplyReferenceSignButton"
                :disabled="isLoading || isGenerateUnRedoLoading"
                @click="applyReferenceSigns()">
          <i class="exi" :class="isLoading ? 'exi-small-spinner-unmasked rotating' : 'exi exi-apply-changes'"/>
        </button>
      </div>
      <div class="right-aligned-stuff">
        <button class="create-button icon-button"
                :title="$t('createReferenceSigns')"
                :disabled="isCreateButtonDisabled"
                @click="createReferenceSigns()">
          <i class="exi exi-plus"></i>
        </button>
        <button class="toggle-show-reference-signs-button icon-button"
                v-show="showHightlightReferenceSignButton"
                :title="this.areReferenceSignsDisplayed ? $t('hideReferenceSigns') : $t('showReferenceSigns')"
                @click="toggleDisplayReferenceSigns()">
          <i :class="this.areReferenceSignsDisplayed ? 'exi exi-view active' : 'exi exi-view'"/>
        </button>
      </div>
      <hr/>
    </div>

    <ReferenceSignDialog ref="refSignDialog" class="hover-button dialog-button" :showButton="false"/>
    <ConfirmationDialog ref="deleteRefSignDialog" titleKey="deleteReferenceSign.title"
                        questionKey="deleteReferenceSign.question"/>

    <div ref="tableContainer"
         class="table-container"
         @scroll="preventScroll">
      <b-table
        :data="referenceSignList"
        :show-header="false"
        narrowed
        :mobile-cards="false"
        ref="table">

        <b-table-column
          field="guid"
          :label="$t(ReferenceSignInputType.LABEL)"
          centered
          numeric
          v-slot="props"
          header-class="table-header">
              <span v-if="props.row.guid === currentGuid">
                  <b-input :id="getRefId(props.row.guid, ReferenceSignInputType.LABEL)"
                           :ref="getRefId(props.row.guid, ReferenceSignInputType.LABEL)"
                           v-bind:class="{invalid: !currentLabelIsValid}" class="label-input"
                           @focusout="saveAndCloseEdit($event, props.row.guid, ReferenceSignInputType.LABEL)"
                           v-model="props.row.labelExplizit"
                           @keyup.enter="closeEditOnEnter(props.row.guid, ReferenceSignInputType.LABEL)"
                           @keyup="isReferenceSignValid(props.row, ReferenceSignInputType.LABEL)"
                           @mousedown.left="mousedownInput()"
                           :maxlength="labelMaxLength"
                           :has-counter="false">
                  </b-input>
              </span>

          <drag v-else
                :disabled="dragDisabled()"
                :data="props.row"
                @dragstart="dragstart">
                <span v-bind:class="(props.row.numberMatchesOnReferenceSign > 0) ? '': 'no-matches'">
                  {{ getLabel(props.row) }}
                </span>
            <template v-slot:drag-image>
              <div class="reference-sign-drag-image">
                <span class="reference-sign-drag-image-label">{{ getEntryLabelForDrag(props.row) }}</span>
              </div>
            </template>
          </drag>
        </b-table-column>

        <b-table-column
          field="name"
          :label="$t('term')"
          v-slot="props"
          header-class="table-header">
              <span v-if="props.row.guid === currentGuid">
                <b-input :id="getRefId(props.row.guid, ReferenceSignInputType.NAME)"
                         :ref="getRefId(props.row.guid, ReferenceSignInputType.NAME)"
                         class="name-input"
                         :class="{invalid: !currentNameIsValid}"
                         @focusout="saveAndCloseEdit($event, props.row.guid, ReferenceSignInputType.NAME)"
                         v-model="props.row.name"
                         @keyup.enter="closeEditOnEnter(props.row.guid, ReferenceSignInputType.NAME)"
                         @keyup.tab="tabToNameInput()"
                         @keydown.tab="tabFromNameInput($event, props.row.guid)"
                         @keyup="isReferenceSignValid(props.row, ReferenceSignInputType.NAME)"
                         @mousedown.left="mousedownInput()"
                         :maxlength="nameMaxLength"
                         :has-counter="false">
                </b-input>
              </span>

          <drag v-else
                class="name-input"
                :disabled="dragDisabled()"
                :data="props.row"
                @dragstart="dragstart"
                @dragend="dragend">
            <div class="cell-text-wrapper">
                  <span class="cell-text" v-bind:class="(props.row.numberMatchesOnReferenceSign > 0) ? '': 'no-matches'">
                    {{ props.row.name }}
                  </span>
              <span v-show="referenceSignContainsWarning(props.row.referenceSign)"
                    class="icon is-small warning-sign-wrapper">
                      <i class="exi exi-warning-sign" :title="$t('ambigiousReferenceSignsHits')"></i>
                  </span>
            </div>
            <template v-slot:drag-image>
              <div class="reference-sign-drag-image">
                <span class="reference-sign-drag-image-label">{{ getEntryLabelForDrag(props.row) }}</span>
              </div>
            </template>
          </drag>
        </b-table-column>

        <b-table-column
          field="actions"
          label=""
          v-slot="props"
          header-class="table-header">
          <div class="button-container"
               v-if="showEditButtons(props.row)">
            <b-button
              class="hover-button"
              @click="clickEdit(props.row.guid)"
              :title="$t('general.edit')"
              :disabled="referenceSignUpdateLoading || isGenerateUnRedoLoading || isApplyReferenceSignLoading">
                  <span class="icon is-small">
                    <i class="exi exi-pen"></i>
                  </span>
            </b-button>
            <b-button
              class="hover-button"
              @click="clickOpenDialog(props.row)"
              :title="$t('configureApplication')"
              :disabled="referenceSignUpdateLoading || isGenerateUnRedoLoading || isApplyReferenceSignLoading">
                  <span class="icon is-small">
                    <i class="exi exi-reference"></i>
                  </span>
            </b-button>
            <b-button
              class="hover-button delete-button"
              @click="clickDelete(props.row)"
              :title="$t('general.delete')"
              :disabled="referenceSignUpdateLoading || isGenerateUnRedoLoading || isApplyReferenceSignLoading">
                  <span class="icon is-small">
                    <i class="exi exi-delete"></i>
                  </span>
            </b-button>
          </div>
          <div class="button-container" v-else-if="props.row.pending">
            <i class="exi exi-small-spinner-unmasked rotating"/>
          </div>
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import ReferenceSignDialog, {ReferenceSignDialog as ReferenceSignDialogClass} from '@/components/ReferenceSignDialog.vue';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import {ReferenceSign, StemFormMatch} from '@/api/models/referenceSign.model';
import ReferenceSignModule from '@/store/modules/ReferenceSignModule';
import {DEFAULT_TABLE_BOTTOM, ReferenceSignInputType, ReferenceSignListEntry} from '@/store/models/referenceSign.model';
import {PatentEngineConstraints} from '@/constraints';
import {cumulativeOffset} from '@/util/dom.util';
import {LocalizedMessageKey} from '@/api/models/exception.model';
import {ErrorWrapper} from '@/util/error.wrapper';
import {
  isReferenceSignLabelStringValid,
  isReferenceSignListEntryLabelUnique,
  isReferenceSignListEntryLabelValid,
  isReferenceSignNameNotEmpty,
  isReferenceSignNameUnique
} from '@/validation/referenceSign.validation';
import {Node as TiptapNode} from '@tiptap/vue-3';
import {ApplicationEditor as ApplicationEditorClass} from '@/components/ApplicationEditor.vue';
import EditorModule from '@/store/modules/EditorModule';
import {MAX_SHOWN_LABEL_LENGTH, shorten} from '@/util/referenceSign.util';
import {Drag} from 'vue-easy-dnd';
import {useDefaultErrorHandling} from '@/errorHandling';

export enum EditReferenceSignMode {
  EDIT_ENABLED,
  EDIT_DISABLED
}

export enum DragMode {
  DRAG_DROP_ENABLED,
  DRAG_DROP_DISABLED
}

@Component(
  {
    components: {
      ConfirmationDialog,
      ReferenceSignDialog,
      Drag
    }
  })
class ReferenceSignList extends Vue {

  @Prop({required: true, default: null})
  private applicationGuid!: string | null;

  @Prop({required: true, default: null})
  private applicationEditorInstance!: ApplicationEditorClass | null;

  @Prop({default: EditReferenceSignMode.EDIT_DISABLED, required: true})
  private editReferenceSignsMode!: EditReferenceSignMode;

  @Prop({default: DragMode.DRAG_DROP_DISABLED, required: true})
  private dragMode!: DragMode;

  @Prop({default: false, required: true})
  private showApplyReferenceSignButton!: boolean;

  @Prop({default: false, required: true})
  private showHightlightReferenceSignButton!: boolean;

  @Ref('referenceSignListRef') private referenceSignListRef!: HTMLElement;
  @Ref('table') private table!: TiptapNode;
  @Ref('refSignDialog') private refSignDialog!: ReferenceSignDialogClass;
  @Ref('deleteRefSignDialog') private deleteRefSignDialog!: ConfirmationDialogClass;
  @Ref('tableContainer') private tableContainer!: HTMLElement;
  private labelMaxLength = PatentEngineConstraints.FIELD_LENGTH_LABEL;
  private nameMaxLength = PatentEngineConstraints.FIELD_LENGTH_NAME;

  private closeCoroutine: number | undefined;
  private closeCoroutineBlock = false; // If this is true, no closeCoroutine will be started
  private currentLabel: string | undefined; // Label of the reference sign currently in edit mode
  private currentName = '';  // Name of the reference sign currently in edit mode
  private currentLabelIsValid = true;
  private currentNameIsValid = true;
  private currentGuid = '';
  private notUniqueError = new ErrorWrapper(LocalizedMessageKey.REFERENCE_SIGN_NOT_UNIQUE_IN_APPLICATION_DOCUMENT);
  private labelIsInvalidError = new ErrorWrapper(LocalizedMessageKey.REFERENCE_SIGN_LABEL_IS_INVALID);
  private nameIsEmptyError = new ErrorWrapper(LocalizedMessageKey.REFERENCE_SIGN_NAME_IS_EMPTY);
  private referenceSignUpdateLoading = false;
  private dragging = false;
  private scrollPosition: number | null = null;

  get ReferenceSignInputType() {
    return ReferenceSignInputType;
  }

  get isLoading(): boolean {
    return ReferenceSignModule.isLoading;
  }

  get isGenerateUnRedoLoading(): boolean {
    return EditorModule.isGenerateUnRedoLoading;
  }

  get applicationGUID(): string | null {
    return this.applicationGuid;
  }

  get applicationEditorInstanceComputed(): ApplicationEditorClass | null {
    return this.applicationEditorInstance;
  }

  get referenceSignList(): ReferenceSignListEntry[] {
    return ReferenceSignModule.referenceSignListEntries;
  }

  get areReferenceSignsDisplayed(): boolean {
    return EditorModule.showReferenceSignsInText;
  }

  get isCreateButtonDisabled(): boolean {
    return this.currentGuid === ReferenceSignModule.referenceSignDummyGuid;
  }

  get isApplyReferenceSignLoading() {
    return EditorModule.isApplyReferenceSignForApplicationDocumentLoading;
  }

  private dragDisabled(): boolean {
    return this.dragMode === DragMode.DRAG_DROP_DISABLED;
  }

  private getEntryLabelForDrag(item: ReferenceSignListEntry): string {
    return item.labelResulting;
  }

  private preventScroll(event: Event) {
    if (this.dragging && this.scrollPosition !== null) {
      this.tableContainer.scrollTop = this.scrollPosition;
    }
  }

  private dragstart() {
    this.dragging = true;
    this.scrollPosition = this.tableContainer.scrollTop;
    setTimeout(() => {
      // Center the drag image at the mouse position
      // eslint-disable-next-line
      const drag = window.document.querySelector('body > .reference-sign-drag-image') as any;
      drag.style.cssText += ' margin-top: -' + (drag.offsetHeight / 2) + 'px;'
      drag.style.cssText += ' margin-left: -' + (drag.offsetWidth / 2) + 'px;'
    }, 0);
  }

  private dragend() {
    this.dragging = false;
    this.scrollPosition = null;
  }

  private showEditButtons(entry: ReferenceSignListEntry): boolean {
    return this.editReferenceSignsMode === EditReferenceSignMode.EDIT_ENABLED
      && !this.currentGuid
      && !entry.edit
      && !entry.pending
      && entry.guid !== ReferenceSignModule.referenceSignDummyGuid;
  }

  private clickEdit(guid: string): void {
    this.referenceSignList.forEach((item: ReferenceSignListEntry) => {
      item.edit = (item.guid === guid);
      if (item.edit) {
        // Editing for this reference sign was activated so remember the original name
        this.currentGuid = item.guid;
        this.currentLabel = item.labelExplizit;
        this.currentName = item.name;
      }
    });
    this.setFocusDelayed(guid); // The delay is needed to make sure the lazy $refs are present
  }

  private clickOpenDialog(referenceSignListEntry: ReferenceSignListEntry): void {
    this.refSignDialog.setWidth(this.getPaneWidth());
    let y = this.getTableBottom();
    // Don't open it too deep
    if (y > 200) {
      y = 200;
    }
    this.refSignDialog.setMargin(y + 'px 0px auto auto');
    this.refSignDialog.open(referenceSignListEntry.referenceSign);
  }

  private clickDelete(referenceSignListEntry: ReferenceSignListEntry): void {
    this.deleteRefSignDialog.open(
      {
        titleKey: 'deleteReferenceSign.title',
        questionKey: 'deleteReferenceSign.question',
        questionValues: [referenceSignListEntry.referenceSign.name],
        options: [
          {
            labelKey: 'general.delete',
            class: 'button-delete',
            callback: () => {
              ReferenceSignModule.deleteReferenceSign({referenceSign: referenceSignListEntry.referenceSign}).catch(useDefaultErrorHandling);
            },
            autofocus: true
          }, {
            labelKey: 'general.cancel',
            class: 'button-cancel'
          }]
      });
  }

  /**
   * Sets the focus to the text field of the given Reference Sign (via guid) delayed by 10ms.
   * The delay is needed to make sure the lazy $refs are present.
   * @param guid guid of the row to jump to
   * @param inputType Default is 'label', can also be 'name'
   * @param delay Default is 10ms
   */
  private setFocusDelayed(guid: string, inputType = ReferenceSignInputType.LABEL, delay = 10): void {
    setTimeout(() => {
      const element = (this.$refs[this.getRefId(guid, inputType)]) as HTMLElement;
      if (element) {
        element.focus();
      }
    }, delay);
  }

  private closeEditOnEnter(guid: string, type: ReferenceSignInputType): void {
    this.closeEdit(guid);
    // in firefox, when pressing enter, the focusout-event triggering the saveAndClose is somehow missing (bug in firefox?)
    // We have to trigger saving manually
    if (this.detectBrowser.isFirefox) {
      this.saveAndCloseEdit(null, guid, type);
    }
  }

  private closeEdit(guid: string): void {
    if (guid === ReferenceSignModule.referenceSignDummyGuid) {
      this.currentGuid = '';
    }
    const referenceSign = this.findReferenceSignByGuid(guid);
    if (referenceSign) {
      referenceSign.edit = false;
      this.currentGuid = '';
    }
  }

  private tabToNameInput(): void {
    // If there is a closing coroutine running - stop it
    if (this.closeCoroutine) {
      clearTimeout(this.closeCoroutine);
    }
  }

  private tabFromNameInput(event: Event, guid: string): void {
    event.preventDefault();

    if (guid === ReferenceSignModule.referenceSignDummyGuid || this.currentNameIsValid) {
      // Instead of staying in this field, jump back to the label field
      this.setFocusDelayed(guid, ReferenceSignInputType.LABEL);
      this.preventCloseCoroutine();
    }
  }

  // Prevent a closeCoroutine from getting started
  private preventCloseCoroutine(): void {
    this.closeCoroutineBlock = true;
    setTimeout(() => {
      this.closeCoroutineBlock = false;
    }, 50);
  }

  private mousedownInput(): void {
    // If a field is invalid don't allow to switch to another one
    if (!this.currentLabelIsValid || !this.currentNameIsValid) {
      return;
    }
    // Prevent a closeCoroutine from getting started (no longer needed)
    this.preventCloseCoroutine();
  }

  private isReferenceSignValid(item: ReferenceSignListEntry, inputType: ReferenceSignInputType): boolean {
    switch (inputType) {
      case ReferenceSignInputType.LABEL: {
        if (item.labelExplizit?.trim() === '') {
          item.labelExplizit = undefined;
        }
        this.currentLabelIsValid = isReferenceSignListEntryLabelValid(item);
        if (this.currentLabelIsValid) {
          this.currentLabelIsValid = isReferenceSignListEntryLabelUnique(item, this.referenceSignList);
        }
        break;
      }
      case ReferenceSignInputType.NAME: {
        this.currentNameIsValid = isReferenceSignNameNotEmpty(item);
        if (this.currentNameIsValid) {
          this.currentNameIsValid = isReferenceSignNameUnique(item, this.referenceSignList);
        }
        break;
      }
    }

    return this.currentLabelIsValid && this.currentNameIsValid;
  }

  // Get the Reference Sign with the given guid from the current present list
  private findReferenceSignByGuid(guid: string): ReferenceSignListEntry | undefined {
    return this.referenceSignList.find((item: ReferenceSignListEntry) => (item.guid === guid));
  }

  // Get the Reference Sign that currently is in edit mode
  private findReferenceSignInEditMode(): ReferenceSignListEntry | undefined {
    return this.referenceSignList.find((item: ReferenceSignListEntry) => (item.edit));
  }

  private saveAndCloseEdit(event: FocusEvent | null, guid: string, inputType: ReferenceSignInputType): void {
    // If the other text field is invalid care about that one first and stop this
    if (inputType === ReferenceSignInputType.LABEL && !this.currentNameIsValid
      || inputType === ReferenceSignInputType.NAME && !this.currentLabelIsValid) {
      return;
    }

    // Find the Reference Sign to save
    const item = this.findReferenceSignByGuid(guid);

    // Init delayed closing to give a chance of interrupting the closing (except we just switched via mousedown)
    this.initCloseCoroutine(guid);

    // If nothing changed - do nothing but close the edit mode
    if (!item || (this.currentLabel === item.labelExplizit && this.currentName === item.name)) {
      return;
    }

    if (item.labelExplizit === '') {
      item.labelExplizit = undefined;
    }

    if (guid === ReferenceSignModule.referenceSignDummyGuid) {
      const nextFocusedElement = event?.relatedTarget as HTMLElement;
      this.handleDummyEdit(item, inputType, nextFocusedElement);
    } else {
      this.handleStandardEdit(item, inputType);
    }
  }

  private handleStandardEdit(item: ReferenceSignListEntry,
                             inputType: ReferenceSignInputType) {
    if (this.isReferenceSignValid(item, inputType)) {
      this.persistReferenceSignChanges(item);
      this.autoRename();
    } else {
      this.cancelChanges(item);
      this.setFocusDelayed(item.guid, inputType);
    }
  }

  private handleDummyEdit(item: ReferenceSignListEntry,
                          inputType: ReferenceSignInputType,
                          nextFocusedElement: HTMLElement | undefined) {
    const nextFocusedElementId = nextFocusedElement && (nextFocusedElement as HTMLElement).parentElement?.id
    const inputFocused = this.getRefId(item.guid, ReferenceSignInputType.LABEL) === nextFocusedElementId
      || this.getRefId(item.guid, ReferenceSignInputType.NAME) === nextFocusedElementId;

    if (!inputFocused) {
      if (this.isReferenceSignValid(item, ReferenceSignInputType.LABEL)
        && this.isReferenceSignValid(item, ReferenceSignInputType.NAME)) {
        this.persistReferenceSignDummy(item);
        this.autoRename();
      } else if (!item.name && !item.labelExplizit) {
        // Fix: ignore validation for empty name
        this.currentLabelIsValid = true;
        this.currentNameIsValid = true;

        ReferenceSignModule.resetReferenceSignDummy();
      } else {
        this.cancelChanges(item);
        this.setFocusDelayed(item.guid, inputType);
      }
    } else {
      if (!this.isReferenceSignValid(item, inputType)) {
        this.cancelChanges(item);
        this.setFocusDelayed(item.guid, inputType);
      } else {
        this.cancelChanges(item, false);
      }
    }
  }

  private persistReferenceSignChanges(item: ReferenceSignListEntry): void {
    this.referenceSignUpdateLoading = true;
    // Do the saving immediately if there was a change
    item.pending = true;

    ReferenceSignModule.updateReferenceSign(
      {
        guid: item.guid,
        applicationDocument: this.applicationGUID!,
        label: item.labelExplizit?.trim(),
        name: item.name,
        stemForms: item.referenceSign.stemForms,
        excludeStemFormMatches: item.referenceSign.excludeStemFormMatches,
      })
      .then(() => {
        // On success remember the new values to not save them again
        this.currentLabel = item.labelExplizit?.trim();
        this.currentName = item.name;
      })
      .catch(useDefaultErrorHandling)
      .finally(() => this.referenceSignUpdateLoading = false);
  }

  private persistReferenceSignDummy(item: ReferenceSignListEntry): void {
    this.referenceSignUpdateLoading = true;
    // Do the saving immediately if there was a change
    item.pending = true;

    ReferenceSignModule.createReferenceSignFromDummy(
      {
        applicationDocument: this.applicationGUID!,
        label: item.labelExplizit,
        name: item.name
      })
      .catch(useDefaultErrorHandling)
      .finally(() => this.referenceSignUpdateLoading = false);
  }

  private cancelChanges(item: ReferenceSignListEntry, validate = true): void {
    if (this.closeCoroutine) {
      clearTimeout(this.closeCoroutine); // Let it open
    }
    item.edit = true;
    this.currentGuid = item.guid;

    if (validate) {
      this.throwEditValidationError(item);
    }
  }

  private initCloseCoroutine(guid: string): void {
    if (!this.closeCoroutineBlock) {
      this.closeCoroutine = setTimeout(() => {
        this.closeEdit(guid);
        this.closeCoroutine = undefined;
      }, 150);
    }
  }

  private throwEditValidationError(item: ReferenceSignListEntry): void {
    if (!item.name) {
      this.nameIsEmptyError.throwError([item.labelExplizit, item.name]);
    } else if (item.labelExplizit != undefined && !isReferenceSignLabelStringValid(item.labelExplizit.trim())) {
      this.labelIsInvalidError.throwError();
    } else {
      this.notUniqueError.throwError([item.labelResulting, item.name]);
    }
  }

  private autoRename(): void {
    let referenceSign = 0;
    this.referenceSignList.forEach((item: ReferenceSignListEntry): void => {
      if (item.labelExplizit) {
        item.labelResulting = item.labelExplizit;
      } else {
        do {
          referenceSign++
        }
        while (this.referenceSignList.some(it => it.labelExplizit === referenceSign.toString()));

        item.labelResulting = referenceSign.toString();
        item.labelExplizit = undefined;
      }
    });
  }

  // Tries to fetch the current width of the containing panel
  private getPaneWidth(): number | undefined {
    return (!this.referenceSignListRef) ? undefined : (this.referenceSignListRef as HTMLElement).offsetWidth;
  }

  // Tries to find the y coordinate of the table's bottom
  private getTableBottom(): number {
    if (!this.table) {
      return DEFAULT_TABLE_BOTTOM; // Default value
    }

    // eslint-disable-next-line
    const tableNode = (this.table as any).$el as HTMLElement;
    const absoluteY = tableNode.offsetHeight + cumulativeOffset(tableNode).top;

    return absoluteY;
  }

  private createReferenceSigns(): void {
    ReferenceSignModule.createReferenceSignDummy(this.applicationGUID!);
    const item = ReferenceSignModule.referenceSignDummy;
    if (item) {
      this.currentGuid = item.guid!;
      this.currentLabel = item.label!;
      this.currentName = item.name;

      this.setFocusDelayed(this.currentGuid, ReferenceSignInputType.NAME);
    }
  }

  private applyReferenceSigns(): void {
    if (!this.isLoading && !this.isGenerateUnRedoLoading && this.applicationGUID) {
      if (this.applicationEditorInstanceComputed) {
        // if we are attached to an editor save blocks first before applying reference signs
        this.applicationEditorInstanceComputed.saveChanges(false)
          .then(() => {
            return ReferenceSignModule.applyReferenceSignsForApplicationDocument({applicationDocumentGuid: this.applicationGUID!});
          })
          .catch(useDefaultErrorHandling);
      } else {
        ReferenceSignModule.applyReferenceSignsForApplicationDocument({applicationDocumentGuid: this.applicationGUID!})
          .catch(useDefaultErrorHandling);
      }
    }
  }

  private referenceSignContainsWarning(sign: ReferenceSign): boolean {
    return sign.stemFormMatches?.find((match: StemFormMatch) => match.collisionWithOtherReferenceSign) !== undefined;
  }

  private getRefId(guid: string, inputType: ReferenceSignInputType): string {
    return inputType + guid.replaceAll('-', '');
  }

  private getLabel(referenceSign: ReferenceSignListEntry): string {
    return shorten(referenceSign.labelResulting, MAX_SHOWN_LABEL_LENGTH);
  }

  private toggleDisplayReferenceSigns(): void {
    EditorModule.toogleShowReferenceSignsInText();
  }

  @Watch('referenceSignList', {deep: true})
  private referenceSignListEntryChanged(newList: ReferenceSignListEntry[], oldList: ReferenceSignListEntry[]): void {
    // If a new ReferenceSign has been added to the list
    if (newList.length === oldList.length + 1) {
      // Scrolls to bottom of the list
      this.scrollToBottom();
    }
  }

  private scrollToBottom(delay = 10): void {
    setTimeout(() => {
      // Scrolls down to the bottom of the list
      this.tableContainer.scrollTop = this.tableContainer.scrollHeight;
    }, delay);
  }

  mounted(): void {
    // Get guid of application document to load the reference signs
    // this.applicationGuid = this.$route.params.applicationGuid;
    if (this.applicationGUID) {
      // If we have a guid - load the reference signs for the current application document
      // - this will be stored in the computed 'referenceSignList'
      ReferenceSignModule.fetchReferenceSignsForApplicationDocument(this.applicationGUID).catch(useDefaultErrorHandling);
    }
  }
}

export default toNative(ReferenceSignList);
</script>
<style scoped lang="scss">
@import 'src/assets/styles/constants';

.reference-sign-list {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: visible;
  text-align: left;
  width: 100%;

  .reference-sign-header {
    border-bottom: 1px solid $pengine-grey;
    display: flex;
    flex-flow: row nowrap;

    .left-aligned-stuff {
      -webkit-user-select: none;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      height: $subheader-height;
      min-height: $subheader-height;
      padding-left: 5px;
      user-select: none;
      width: inherit;

      h2 {
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 12px;
        width: max-content;
      }

      button {
        margin-left: 10px;
        margin-top: 0;

        &:disabled {
          border: none;
        }
      }
    }

    .right-aligned-stuff {
      display: flex;
      flex-flow: row-reverse nowrap;
      width: 100%;

      .toggle-show-reference-signs-button {
        i {
          height: 2em;
          width: 2em;

          &.active {
            background-color: $pengine-reference-sign;
          }

          &:hover {
            background-color: $pengine-blue-dark;
          }
        }
      }

      .create-button {
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
    }
  }

  .table-container {
    font-size: $font-size-normal;
    height: inherit;
    overflow: visible;
    overflow-y: auto;
    width: 100%;

    :deep(.b-table) {
      .table-wrapper {
        .table {
          border: transparent !important;

          .button-container {
            height: $row-height;
            float: right;

            .hover-button {
              visibility: hidden;
              transition: none;
            }

            .button {
              height: $row-height;
              background-color: transparent;
              border: none;
              padding: 0px 10px 0px 10px;
            }

            .exi-small-spinner-unmasked {
              margin-right: 10px;
            }
          }

          tr:hover .hover-button {
            visibility: visible !important;
          }


          tr {
            background-color: transparent;
            height: $row-height;

            td {
              border-bottom: none;
              border-top: none;
              padding: 0;
              vertical-align: middle;

              .no-matches {
                color: $pengine-document-structure-tree-empty-block;
              }

              .cell-text-wrapper {
                align-items: center;
                display: flex;
                flex-flow: row nowrap;

                .cell-text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .no-matches {
                  color: $pengine-document-structure-tree-empty-block;
                }

                .warning-sign-wrapper {
                  height: 18px;
                  margin: 0 5px;
                  width: 18px;

                  i {
                    background-color: $pengine-warn-yellow;
                  }
                }
              }

              // Both input types
              input {
                color: $text-color;
                font-family: $text-font;
                font-size: $font-size-normal;
                height: 23px !important;
                padding: 1px 4px 0px 4px;
              }

              // Label input
              div:has(.label-input) {
                input:enabled {
                  &:hover, &:focus {
                    padding-left: 4px !important;
                  }
                }
              }

              div:has(.name-input) {
                padding-left: 3px;
              }

              // Name input
              div:has(:not(.label-input)) {
                input:enabled {
                  &:hover, &:focus {
                    padding-left: 3px !important;
                  }
                }
              }

              .counter {
              }

              &:first-child {
                .input {
                  text-align: center;
                }

                padding-left: $reference-sign-label-padding-left;
                width: $reference-sign-label-width;
              }

              &:nth-child(2) {
                max-width: 160px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &:nth-child(3) {
                white-space: nowrap;
                width: 80px; // Fixed width for the right button container
              }
            }
          }
        }
      }
    }
  }
}
</style>