import * as paper from 'paper';
import {
  Alignment,
  ARROW_ANGLE,
  ARROW_SIZE,
  BASELINE_FACTOR,
  BoundingBox,
  Brace,
  BRACE_CIRCLE_RADIUS,
  BraceType,
  Curve,
  DASHED_CURVE,
  DASHED_LINE,
  DrawingBaseModuleConfig,
  DrawingBaseModuleFigureInfo,
  DrawingBaseModuleInput,
  FigureInfoTextName,
  FigureOrientation,
  FigureSymbol,
  FigureSymbolSubType,
  FigureSymbolType,
  HelpLine,
  ImageType,
  LayerName,
  Line,
  MIN_SIZE_CURVE_HANDLE,
  OFFSET,
  Orientation,
  ReferenceSignMarker,
  ReferredReferenceSign,
  RESOLUTION,
  SelectionConfig,
  SIZES,
  STROKE_WIDTH,
  STYLES,
  TRANSPARENT_COLOR
} from './api/models/drawingbase.model';
import {compareStrings} from './utils/drawingBaseModule.utils';

const MAGNETIC_RANGE = 20;

/**
 * Helper class that creates a paperjs-based project in A4 format with High Resolution.
 */
export class FigureCanvas {
  private project!: paper.Project;
  private figureInfo: DrawingBaseModuleFigureInfo;
  private config: DrawingBaseModuleConfig;

  private readonly width: number;
  private readonly height: number;

  /**
   * Computes the offset based on the given resolution of the application figure.
   *
   * @returns {number}
   */
  public get offset(): number {
    return this.cmToPixels(OFFSET);
  }

  /**
   * Parses the given number of centimeters to pixels.
   * @param cm The number of centimiters to be parsed.
   * @return The corresponding number of pixels to the given number of centimiters.
   */
  private cmToPixels = (cm: number): number => {
    const dpi = RESOLUTION * this.factor;
    return dpi * cm / 2.54;
  }

  /**
   * The scaling factor to be applied to the elements of the canvas.
   */
  public get factor(): number {
    return this.height / SIZES.A4[this.figureInfo.orientation].height;
  }

  /**
   * The scaling point for paperjs.
   */
  public get scaling(): paper.Point {
    const factor = this.factor;
    return new paper.Point(factor, factor);
  }

  /**
   * Translate the canvas position to the original position using #factor.
   * @param canvasPoint - Point in the canvas coordinate system
   * @returns {paper.Point} - Point in the original (model) coordinate system
   */
  public getOriginalPoint(canvasPoint: paper.Point): paper.Point {
    return new paper.Point(canvasPoint.x / this.factor, canvasPoint.y / this.factor);
  }

  /**
   * Translate the original position to the canvas position using #factor.
   * @param originalPoint - Point in the original (model) coordinate system
   * @returns {paper.Point} - Point in the canvas coordinate system
   */
  public getCanvasPoint(originalPoint: paper.Point): paper.Point {
    return new paper.Point(originalPoint.x * this.factor, originalPoint.y * this.factor);
  }

  /**
   * Creates a new instance of an HdCanvas object.
   * @param input The object containing the information for the representation of a Figure within a paperjs-based canvas.
   * @param width The width of the figure canvas.
   * @param height The height of the figure canvas.
   */
  constructor(input: DrawingBaseModuleInput, width: number, height: number) {
    this.figureInfo = input.figureInfo;
    this.config = input.config;
    this.width = width;
    this.height = height;
  }

  /**
   * Maps given application figure to a paper.js project with different layers.
   *
   * @returns {Promise<paper.Project>}
   */
  public async getProject(): Promise<paper.Project> {
    // Sets bigger handle size
    paper.settings.handleSize = 8;

    try {
      this.project = new paper.Project(new paper.Size(this.width, this.height));

      if (this.config.withBackground) {
        await this.addBackgroundLayer(this.project);
      }
      if (this.config.withInfoTexts) {
        await this.addExtraFigureInfoTexts(this.project);
      }
      if (this.config.withSymbols) {
        this.addSymbolsLayer(this.project);
      }
      if (this.config.withHelpLines) {
        this.addHelpLines(this.project);
      }
      return this.project;
    } catch (err) {
      if (this.project) {
        this.project.clear();
        this.project.remove();
      }
      throw new Error(`Error occured when getting project: ${err}`);
    }
  }

  /**
   * Adds a layer with the figure info texts like title and figure number.
   */
  private async addExtraFigureInfoTexts(project: paper.Project): Promise<void> {
    const figureInfoTextsLayer = new paper.Layer({insert: false});

    figureInfoTextsLayer.name = LayerName.FIGURE_INFO_TEXTS;
    project.addLayer(figureInfoTextsLayer);

    figureInfoTextsLayer.activate();
    figureInfoTextsLayer.addChild(this.insertTitle());
    figureInfoTextsLayer.addChild(this.insertFigureNumber());

    if (!this.figureInfo.showFigureNumber) {
      figureInfoTextsLayer.visible = false;
    }
  }

  /**
   * Inserts a paper.js PointText item with the title of the figure (e.g. Fig.2).
   * Position is about 2.5cm above the bottom of the figure.
   *
   * @returns {paper.PointText}
   */
  private insertTitle(): paper.PointText {
    const title = new paper.PointText(
      {
        ...STYLES.TITLE,
        name: FigureInfoTextName.TITLE,
        content: `Fig. ${this.figureInfo.position + 1}`,
      });
    title.scaling = this.scaling;
    const offsetY = this.project.view.bounds.height - this.offset - title.bounds.height * BASELINE_FACTOR;
    title.point = new paper.Point(this.project.view.center.x, offsetY);

    return title;
  }

  /**
   * Inserts a paper.js PointText item with the figure number and total number of figure (e.g. 2/6).
   *
   * @returns {paper.PointText}
   */
  private insertFigureNumber(): paper.PointText {
    switch (this.figureInfo?.orientation) {
      case FigureOrientation.LANDSCAPE: {
        return this.insertFigureNumberLandscape();
      }
      case FigureOrientation.PORTRAIT: {
        return this.insertFigureNumberPortrait();
      }
    }
  }

  /**
   * Inserts a paper.js PointText item with the figure number and total number of figure (e.g. 2/6) for an application figure with
   * a landscape orientation.
   * Position is about 2.5cm left from the right border of the figure and rotated 90 degrees.
   *
   * @returns {paper.PointText}
   */
  private insertFigureNumberLandscape(): paper.PointText {
    const content = `${this.figureInfo.position + 1}/${this.figureInfo.totalFiguresInDocument}`;
    const figureNumber: paper.PointText = new paper.PointText(
      {
        ...STYLES.FIGURE_NUMBER,
        name: FigureInfoTextName.FIGURE_NUMBER,
        content: content,
        rotation: 90
      });
    figureNumber.scaling = this.scaling;

    const offsetX = this.project.view.bounds.width - this.offset - (figureNumber.bounds.width / (1 + BASELINE_FACTOR));
    figureNumber.point = new paper.Point(offsetX, this.project.view.center.y);

    return figureNumber;
  }

  /**
   * Inserts a paper.js PointText item with the figure number and total number of figure (e.g. 2/6) for an application figure with
   * a portrait orientation.
   * Position is about 2.5cm below the top of the figure.
   *
   * @returns {paper.PointText}
   */
  private insertFigureNumberPortrait(): paper.PointText {
    const figureNumber = new paper.PointText(
      {
        ...STYLES.FIGURE_NUMBER,
        name: FigureInfoTextName.FIGURE_NUMBER,
        content: `${this.figureInfo.position + 1}/${this.figureInfo.totalFiguresInDocument}`
      });
    figureNumber.scaling = this.scaling;


    const offsetY = this.offset + (figureNumber.bounds.height / (1 + BASELINE_FACTOR));
    figureNumber.point = new paper.Point(this.project.view.center.x, offsetY);
    return figureNumber;
  }

  /**
   * Adds a background layer with the loaded image - supported types: png/jpg/svg.
   */
  private async addBackgroundLayer(project: paper.Project): Promise<void> {
    const backgroundLayer = new paper.Layer({insert: false});
    backgroundLayer.name = LayerName.BACKGROUND;
    project.addLayer(backgroundLayer);

    backgroundLayer.activate();
    this.addWhiteBackground();

    const baseImage: string = this.figureInfo.image ? this.figureInfo.image.toString('base64') : '';
    switch (this.figureInfo.imageType) {
      case ImageType.PNG:
      case ImageType.JPG: {
        const raster = await this.getBackgroundRaster(baseImage, this.figureInfo.imageType);
        backgroundLayer.addChild(raster).scale(this.figureInfo.scaling);
        break;
      }
      case ImageType.SVG: {
        const svg = await this.getBackgroundSvg(baseImage);
        backgroundLayer.addChild(svg).scale(this.figureInfo.scaling);
        break;
      }
      default:
        throw new Error(`Given image type ${this.figureInfo.imageType} is not supported.`);
    }
  }

  /**
   * Creates the raster with a background image - accepted types are png and jpg.
   * @param baseImage The base image as base64 string.
   * @param format The format to be used in the raster.
   *
   * @returns {Promise<paper.Raster>}
   */
  private async getBackgroundRaster(baseImage: string, format: string): Promise<paper.Raster> {
    // eslint-disable-next-line no-undef
    return new Promise((resolve) => {
      const imageUrl: string = baseImage
        ? `data:image/${format};charset=utf-8;base64,` + baseImage
        : '';
      // some default image

      const image: paper.Raster = new paper.Raster({source: imageUrl, position: this.project.view.center});
      image.onLoad = () => {
        if (image) {
          image.fitBounds(this.project.view.bounds);
        }
        resolve(image);
      };
    });
  }

  /**
   * Reads and imports a given svg file.
   * @param baseImage The base image as base64 string.
   *
   * @returns {Promise<paper.Item>}
   */
  private async getBackgroundSvg(baseImage: string): Promise<paper.Item> {
    const imageUrl = baseImage
      ? `data:image/svg;charset=utf-8;base64,` + baseImage
      : ''; // some default image

    return new Promise((resolve, reject) => {
      this.project.activeLayer.importSVG(imageUrl, {
        insert: false,
        applyMatrix: false,
        onLoad: (svg: paper.Item) => {
          if (svg) {
            svg.fitBounds(this.project.view.bounds);
          }
          resolve(svg);
        },
        onError: reject
      });
    });
  }

  /**
   * Adds a symbols layer with paper.js objects.
   */
  private addSymbolsLayer(project: paper.Project): void {
    const symbolsLayer = new paper.Layer({insert: false});
    symbolsLayer.name = LayerName.SYMBOLS;
    project.addLayer(symbolsLayer);

    symbolsLayer.activate();

    // For time being, figures only have one layer
    this.figureInfo.symbols
      .filter(it => this.config.withUserHelpLines || it.symbolType !== FigureSymbolType.HELP_LINE)
      .forEach((symbol: FigureSymbol) => {
        if (symbol.guid) {
          symbolsLayer.addChild(this.createSymbol(symbol));
        }
      });
  }

  /**
   * Compounds a paperJs object from the given FigureSymbol object.
   * @param symbol The FigureSymbol object to be used for compounding the paper.PointText object to be returned.
   *
   * @returns {paper.Group}
   */
  // @ts-ignore
  public createSymbol(symbol: FigureSymbol): paper.Group {
    switch (symbol.symbolType) {
      case FigureSymbolType.REFERENCE_SIGN_MARKER: {
        return this.createReferenceSignMarker(symbol as ReferenceSignMarker);
      }
      case FigureSymbolType.LINE: {
        return this.createLine(symbol as Line);
      }
      case FigureSymbolType.ARROW: {
        return this.createArrow(symbol as Line);
      }
      case FigureSymbolType.CURVE: {
        return this.createCurve(symbol as Curve);
      }
      case FigureSymbolType.BRACE: {
        return this.createBrace(symbol as Brace);
      }
      case FigureSymbolType.HELP_LINE: {
        return this.createHelpLine(symbol as HelpLine);
      }
      default: {
        const exhaustiveCheck: never = symbol.symbolType;
        throw new Error(`Given symbol type ${symbol.symbolType} is not supported.`);
      }
    }
  }

  /**
   * Create a paper.js Group with several sub-items which represent one or many reference sign markers.
   *
   * @param referenceSignMarker {ReferenceSignMarker} - symbol model
   * @returns {paper.Group}
   */
  public createReferenceSignMarker(referenceSignMarker: ReferenceSignMarker): paper.Group {
    const sortReferenceSigns = (list: ReferredReferenceSign[]): ReferredReferenceSign[] => {
      return [...list]
        .sort((a, b) => compareStrings(a.assignedLabel, b.assignedLabel));
    }

    const createGroup = (): paper.Group => {
      return new paper.Group(
        {
          name: referenceSignMarker.guid,
          data: {
            type: FigureSymbolType.REFERENCE_SIGN_MARKER,
          }
        });
    }

    const createComma = (bounds: paper.Rectangle): paper.PointText => {
      const result = new paper.PointText(
        {
          ...STYLES.REFERENCE_SIGN,
          content: ", ",
          data: {
            selectionConfig: {
              neverSelect: true
            } as SelectionConfig,
          },
          scaling: this.scaling
        });
      // append text to the right of the group's current bounds
      result.bounds.topLeft = bounds.topRight;
      return result;
    }

    const createReferenceText = (bounds: paper.Rectangle, referenceSign: ReferredReferenceSign): paper.PointText => {
      const result = new paper.PointText(
        {
          ...STYLES.REFERENCE_SIGN,
          content: referenceSign.assignedLabel,
          data: {
            type: FigureSymbolSubType.REFERENCE_SIGN_TEXT,
            selectionConfig: {
              neverSelect: true
            } as SelectionConfig,
            referenceSign: referenceSign
          },
          scaling: this.scaling
        });
      // append text to the right of the group's current bounds
      result.bounds.topLeft = bounds.topRight;
      return result;
    }

    const createUnderline = (bounds: paper.Rectangle): paper.Path => {
      return new paper.Path.Line(
        {
          strokeWidth: STROKE_WIDTH * this.factor,
          data: {
            type: FigureSymbolSubType.REFERENCE_SIGN_UNDERLINE,
            selectionConfig: {
              neverSelect: true
            } as SelectionConfig
          },
          from: bounds.bottomLeft,
          to: bounds.bottomRight,
          locked: true
        });
    }

    const createSelectionRectangle = (bounds: paper.Rectangle): paper.Path => {
      // Rectangle is transparent when not selected
      return new paper.Path.Rectangle(
        {
          ...STYLES.SELECTION_RECTANGLE,
          rectangle: bounds,
          strokeColor: TRANSPARENT_COLOR,
          locked: true,
          data: {
            selectionConfig: {
              colorForCustomSelectionMarker: STYLES.SELECTION_RECTANGLE.selectedColor
            } as SelectionConfig
          }
        });
    }

    // surrounding group
    const group = createGroup();

    // draw text, comma, text, ...
    sortReferenceSigns(referenceSignMarker.referenceSigns)
      .forEach((referenceSign, index) => {
        if (index != 0) {
          group.addChild(createComma(group.bounds));
        }
        group.addChild(createReferenceText(group.bounds, referenceSign));
      });

    // draw underline (maybe transparent)
    group.addChild(createUnderline(group.bounds));
    // draw selection rectangle (only visible when selected)
    group.addChild(createSelectionRectangle(group.bounds));

    // adjust group's center, this also moves the content
    group.data.boundingBox = this.calcBoundingBox(referenceSignMarker, group);
    group.data.vertical = referenceSignMarker.vertical;
    group.data.horizontal = referenceSignMarker.horizontal;
    group.position = this.getCanvasPoint(group.data.boundingBox.getMidPoint());

    // change underline color
    this.changeReferenceSignMarkerUnderlining(group, referenceSignMarker.underlined);

    return group;
  }

  /**
   * Handles the movement of a reference sign marker. This method updates the marker's position
   * relative to nearby help lines, ensuring it snaps to them if within a magnetic range.
   *
   * @param item The Paper.js item representing the reference sign marker
   * @param target The target point associated with the marker's movement
   * @param allowDocking
   */
  public onReferenceSignMarkerMoved(item: paper.Item, target: paper.Point, allowDocking = true) {
    const layer = item.parent;

    if (!layer) {
      return;
    }
    item.data.vertical = null;
    item.data.horizontal = null;

    layer.children
      .map((child: any) => {
        if (child === item || child.data.type !== FigureSymbolType.HELP_LINE) {
          return {distance: -1, child};
        }
        const line = child.children[0] as paper.PathItem;
        const position = item.position;
        return {distance: position.getDistance(line.getNearestPoint(position)), child};
      })
      .filter(it => it.distance !== -1)
      .sort((a, b) => b.distance - a.distance)
      .forEach(it => {
        const line = it.child.children[0] as paper.PathItem;
        const position = item.position;
        const boundingBox = item.data.boundingBox;
        const boxSize = it.child.data.orientation === Orientation.VERTICAL ? boundingBox.getWidth() : boundingBox.getHeight();

        if (allowDocking && it.distance < ((boxSize + STROKE_WIDTH) / 2 + MAGNETIC_RANGE)) {
          item.position = line.getNearestPoint(position);
          if (!it.child.data.dockedSymbols.includes(item.name)) {
            it.child.data.dockedSymbols.push(item.name);
          }
          this.placeTowardsHelpLine(item, it.child, target, boundingBox);
        } else {
          it.child.data.dockedSymbols = it.child.data.dockedSymbols.filter((it: string) => it !== item.name);
        }
      });
  }

  /**
   * Adjusts the position of the item to align towards the help line based on the event point and bounding box.
   *
   * @param item The Paper.js item representing the reference sign marker
   * @param helpLine The Paper.js group representing the help line
   * @param target The target point associated with the marker's movement
   * @param boundingBox The bounding box of the item being moved
   * @private
   */
  private placeTowardsHelpLine(item: paper.Item, helpLine: paper.Group, target: paper.Point, boundingBox: BoundingBox) {
    if (helpLine.data.orientation === Orientation.VERTICAL) {
      const offset = target.x > helpLine.position.x ? 1 : -1;
      const alignment = target.x > helpLine.position.x ? Alignment.RIGHT : Alignment.LEFT;

      item.data.vertical = {helpLineGuid: helpLine.name, alignment};
      item.position.x = item.position.x + (offset * (boundingBox.getWidth() + STROKE_WIDTH)) / 2;
    }
    if (helpLine.data.orientation === Orientation.HORIZONTAL) {
      const offset = target.y > helpLine.position.y ? 1 : -1;
      const alignment = target.y > helpLine.position.y ? Alignment.BOTTOM : Alignment.TOP;

      item.data.horizontal = {helpLineGuid: helpLine.name, alignment};
      item.position.y = item.position.y + (offset * (boundingBox.getHeight() + STROKE_WIDTH)) / 2;
    }
  }

  /**
   * Calculates and returns the bounding box for the given reference sign marker and group.
   * The bounding box is adjusted based on the marker's alignment properties and the group's dimensions.
   *
   * @param referenceSignMarker The reference sign marker containing initial bounding box data and alignment properties
   * @param group The Paper.js group whose dimensions are used to adjust the bounding box
   * @returns {BoundingBox} The calculated bounding box
   */
  public calcBoundingBox(referenceSignMarker: ReferenceSignMarker, group: paper.Group): BoundingBox {
    const boundingBox = new BoundingBox(referenceSignMarker.topLeftX, referenceSignMarker.topLeftY,
                                        referenceSignMarker.bottomRightX, referenceSignMarker.bottomRightY);

    if (!referenceSignMarker.vertical && !referenceSignMarker.horizontal) {
      const midPointX = boundingBox.getMidPoint().x;
      const midPointY = boundingBox.getMidPoint().y;

      boundingBox.topLeftX = midPointX - (group.bounds.width / 2);
      boundingBox.topLeftY = midPointY - (group.bounds.height / 2);
      boundingBox.bottomRightX = midPointX + (group.bounds.width / 2);
      boundingBox.bottomRightY = midPointY + (group.bounds.height / 2);
    } else {
      if (referenceSignMarker.vertical) {
        if (referenceSignMarker.vertical.alignment === Alignment.RIGHT) {
          boundingBox.bottomRightX = boundingBox.topLeftX + group.bounds.width;
        } else if (referenceSignMarker.vertical.alignment === Alignment.LEFT) {
          boundingBox.topLeftX = boundingBox.bottomRightX - group.bounds.width;
        }
        if (!referenceSignMarker.horizontal) {
          boundingBox.topLeftY = boundingBox.getMidPoint().y - (group.bounds.height / 2);
          boundingBox.bottomRightY = boundingBox.getMidPoint().y + (group.bounds.height / 2);
        }
      }
      if (referenceSignMarker.horizontal) {
        if (referenceSignMarker.horizontal.alignment === Alignment.TOP) {
          boundingBox.bottomRightY = boundingBox.topLeftY + group.bounds.height;
        } else if (referenceSignMarker.horizontal.alignment === Alignment.BOTTOM) {
          boundingBox.topLeftY = boundingBox.bottomRightY - group.bounds.height;
        }
        if (!referenceSignMarker.vertical) {
          boundingBox.topLeftX = boundingBox.getMidPoint().x - (group.bounds.width / 2);
          boundingBox.bottomRightX = boundingBox.getMidPoint().x + (group.bounds.width / 2);
        }
      }
    }
    return boundingBox;
  }

  /**
   * Check if underline is currently switched on.
   *
   * @param group
   */
  public isReferenceSignMarkerUnderlined(group: paper.Group): boolean {
    return !!group.data.underlined;
  }

  /**
   * Change color of underline between visible and transparent.
   *
   * @param group
   * @param shouldBeUnderlined
   */
  public changeReferenceSignMarkerUnderlining(group: paper.Group, shouldBeUnderlined: boolean) {
    group.data.underlined = shouldBeUnderlined;
    const strokeColor = shouldBeUnderlined ? STYLES.REFERENCE_SIGN_UNDERLINE.strokeColor : TRANSPARENT_COLOR;
    group.children
      .filter(child => child.data.type === FigureSymbolSubType.REFERENCE_SIGN_UNDERLINE)
      .forEach(child => child.strokeColor = new paper.Color(strokeColor));
  }

  /**
   * Creates a paper.js Line inside a Group.
   * @param lineSymbol The figure symbol as a line.
   *
   * @returns {paper.Group}
   * @private
   */
  private createLine(lineSymbol: Line): paper.Group {
    const styles = lineSymbol.dashed ? {...STYLES.LINE, ...STYLES.DASHED_LINE} : STYLES.LINE;
    const start = new paper.Point(lineSymbol.x1 * this.factor, lineSymbol.y1 * this.factor);
    const line = new paper.Path.Line(
      {
        ...styles,
        strokeWidth: STROKE_WIDTH * this.factor,
        data: {
          type: FigureSymbolSubType.LINE_PATH
        },
        from: start,
        to: [lineSymbol.x2 * this.factor, lineSymbol.y2 * this.factor],
      });

    return new paper.Group(
      {
        children: [line],
        data: {
          type: FigureSymbolType.LINE,
          dashed: lineSymbol.dashed
        },
        name: lineSymbol.guid,
      });
  }

  public createHelpLine(helpLine: HelpLine): paper.Group {
    const startingPoint = this.createHelpLineStartingPoint(helpLine.orientation, helpLine.coordinate);
    const endPoint = this.createHelpLineEndPoint(helpLine.orientation, helpLine.coordinate);
    const line = new paper.Path.Line(
      {
        ...STYLES.USER_HELP_LINES,
        data: {
          type: FigureSymbolSubType.BRACE_HELPLINE,
          selectionConfig: {
            colorForCustomSelectionHelpLine: STYLES.USER_HELP_LINES.selectedColor
          } as SelectionConfig
        },
        selectedColor: TRANSPARENT_COLOR,
        from: startingPoint,
        to: endPoint
      });

    const dockedSymbols = [];

    if (helpLine.vertical) {
      dockedSymbols.push(helpLine.vertical.referenceSignMarkerGuid);
    }
    if (helpLine.horizontal) {
      dockedSymbols.push(helpLine.horizontal.referenceSignMarkerGuid);
    }
    return new paper.Group(
      {
        children: [line],
        data: {
          type: FigureSymbolType.HELP_LINE,
          orientation: helpLine.orientation,
          dockedSymbols
        },
        name: helpLine.guid,
      });
  }

  /**
   * Handles the movement of a help line. This method updates the position of the help line's segments
   * based on the item's current position and orientation.
   *
   * @param item The Paper.js item representing the help line
   */
  public onHelpLineMoved(item: paper.Item) {
    if (!item.children || !item.children.length) {
      return;
    }
    const line = item.children[0] as paper.Path.Line;
    const position = item.position;
    const coordinate = item.data.orientation === Orientation.HORIZONTAL ? position.y : position.x;

    line.segments[0].point = this.createHelpLineStartingPoint(item.data.orientation, coordinate);
    line.segments[1].point = this.createHelpLineEndPoint(item.data.orientation, coordinate);
  }

  /**
   * Creates the starting point for the help line based on its orientation and coordinate.
   *
   * @param orientation The orientation of the help line (HORIZONTAL or VERTICAL)
   * @param coordinate The coordinate (x or y) based on the help line's orientation
   * @returns {paper.Point} - The starting point of the help line
   * @private
   */
  private createHelpLineStartingPoint(orientation: Orientation, coordinate: number): paper.Point {
    return orientation === Orientation.HORIZONTAL
      ? new paper.Point(
        this.offset,
        this.clamp(coordinate, this.offset, this.project.view.bounds.height - this.offset + STYLES.USER_HELP_LINES.strokeWidth))
      : new paper.Point(
        this.clamp(coordinate, this.offset, this.project.view.bounds.width - this.offset + STYLES.USER_HELP_LINES.strokeWidth),
        this.offset);
  }

  /**
   * Creates the end point for the help line based on its orientation and coordinate.
   *
   * @param orientation The orientation of the help line (HORIZONTAL or VERTICAL)
   * @param coordinate The coordinate (x or y) based on the help line's orientation
   * @returns {paper.Point} The end point of the help line
   * @private
   */
  private createHelpLineEndPoint(orientation: Orientation, coordinate: number): paper.Point {
    return orientation === Orientation.HORIZONTAL
      ? new paper.Point(
        this.project.view.bounds.width - this.offset + STYLES.USER_HELP_LINES.strokeWidth,
        this.clamp(coordinate, this.offset, this.project.view.bounds.height - this.offset + STYLES.USER_HELP_LINES.strokeWidth))
      : new paper.Point(
        this.clamp(coordinate, this.offset, this.project.view.bounds.width - this.offset + STYLES.USER_HELP_LINES.strokeWidth),
        this.project.view.bounds.height - this.offset + STYLES.USER_HELP_LINES.strokeWidth);
  }

  /**
   * Clamps a value between a minimum and maximum value.
   *
   * @param value The value to be clamped
   * @param min The minimum value
   * @param max The maximum value
   * @returns {number} The clamped value, constrained to be within the range [min, max]
   * @private
   */
  private clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(max, value));
  }

  /**
   * Prepares a paper.js Path with one segment representing a curve inside a Group.
   * @param curveSymbol The figure symbol as a bezier curve.
   *
   * @returns {paper.Group}
   * @private
   */
  public initializeCurve(curveSymbol: any): paper.Group {
    const curve = new paper.Path(
      {
        ...STYLES.CURVE,
        data: {
          type: FigureSymbolSubType.CURVE_PATH,
          selectionConfig: {
            fullySelectedAutomatically: true
          } as SelectionConfig
        },
        fullySelected: true, // show handles
      });
    const point = new paper.Point(curveSymbol.x1 * this.factor, curveSymbol.y1 * this.factor);
    const minPoint = new paper.Point(MIN_SIZE_CURVE_HANDLE, 0);
    curve.addSegments([new paper.Segment(point, minPoint, minPoint.multiply(-1))]);

    return new paper.Group(
      {
        children: [curve],
        name: curveSymbol.guid,
        data: {
          type: FigureSymbolType.CURVE,
          dashed: curveSymbol.dashed
        },
        scaling: this.scaling,
      });
  }

  /**
   * Curves the paper.Path on its starting point (first segment).
   * @param curveGroup The paper.Group with curved Path.
   * @param delta The delta of the given handles
   * @param segmentIndex The index of the segment to curve (0: first, 1: last)
   * @param handleName The name of the handle on the curve segment ('handle-out' or 'handle-in').
   */
  public curveSegment(curveGroup: paper.Group, delta: paper.Point, segmentIndex: number, handleName: string): void {
    const path: paper.Path = curveGroup.children
      .find((child: paper.Item) => child.data.type === FigureSymbolSubType.CURVE_PATH) as paper.Path;

    if (path) {
      const effectiveDelta = delta.multiply(handleName === 'handle-in' ? -1 : 1);
      path.segments[segmentIndex].handleIn.x -= effectiveDelta.x;
      path.segments[segmentIndex].handleIn.y -= effectiveDelta.y;
      path.segments[segmentIndex].handleOut.x += effectiveDelta.x;
      path.segments[segmentIndex].handleOut.y += effectiveDelta.y;
      path.fullySelected = true;
    }
  }

  /**
   * Adds the endpoint to the curve.
   * @param curveGroup The paper.Group with curved Path.
   * @param endPoint The endpoint of the curve.
   *
   * @returns {paper.Group}
   */
  public finalizeCurve(curveGroup: paper.Group, endPoint: paper.Point): paper.Group {
    const path = curveGroup.children
      .find((child) => child.data.type === FigureSymbolSubType.CURVE_PATH) as paper.Path;

    if (path) {
      const point = this.getCanvasPoint(endPoint);
      const handle = new paper.Point(MIN_SIZE_CURVE_HANDLE, 0);
      path.addSegments([new paper.Segment(point, handle, handle.multiply(-1))]);
    }
    return curveGroup;
  }

  /**
   * Creates a paper.Group representing a bezier curve in the canvas.
   * @param curveSymbol The Curve object containing all the information to create a bezier curve paper.Group object.
   *
   * @returns {paper.Group}
   */
  public createCurve(curveSymbol: Curve): paper.Group {
    const curve = new paper.Path(
      {
        ...STYLES.CURVE,
        strokeWidth: STROKE_WIDTH * this.factor,
        data: {
          type: FigureSymbolSubType.CURVE_PATH,
          selectionConfig: {
            fullySelectedAutomatically: true
          } as SelectionConfig
        },
        segments: [[curveSymbol.x1 * this.factor, curveSymbol.y1 * this.factor]] // Start point
      });

    if (curveSymbol.dashed) {
      curve.dashArray = DASHED_CURVE;
    }
    if (curveSymbol.handle1x !== undefined && curveSymbol.handle1y !== undefined &&
      curveSymbol.handle2x !== undefined && curveSymbol.handle2y !== undefined) {
      curve.segments[0].handleIn.x = curveSymbol.handle1x * this.factor;
      curve.segments[0].handleIn.y = curveSymbol.handle1y * this.factor;
      curve.segments[0].handleOut.x = -curveSymbol.handle1x * this.factor;
      curve.segments[0].handleOut.y = -curveSymbol.handle1y * this.factor;

      curve.add(new paper.Point(curveSymbol.x2 * this.factor, curveSymbol.y2 * this.factor));
      curve.segments[1].handleIn.x = curveSymbol.handle2x * this.factor;
      curve.segments[1].handleIn.y = curveSymbol.handle2y * this.factor;
      curve.segments[1].handleOut.x = -curveSymbol.handle2x * this.factor;
      curve.segments[1].handleOut.y = -curveSymbol.handle2y * this.factor;
    }
    return new paper.Group(
      {
        children: [curve],
        name: curveSymbol.guid,
        data: {
          type: FigureSymbolType.CURVE,
          dashed: curveSymbol.dashed
        },
      });
  }

  /**
   * Creates a paper.js Line with arrowhead inside a Group.
   * @param lineSymbol The figure symbol as a line.
   *
   * @returns {paper.Group}
   * @private
   */
  private createArrow(lineSymbol: Line): paper.Group {
    const start = new paper.Point(lineSymbol.x1 * this.factor, lineSymbol.y1 * this.factor);
    const end = new paper.Point(lineSymbol.x2 * this.factor, lineSymbol.y2 * this.factor);
    const styles = lineSymbol.dashed ? {...STYLES.ARROW, ...STYLES.DASHED_LINE} : STYLES.ARROW;
    const arrowTail = new paper.Path.Line(
      {
        ...styles,
        strokeWidth: STROKE_WIDTH * this.factor,
        segments: [start, end],
        data: {
          type: FigureSymbolSubType.ARROW_TAIL
        },
      });

    const arrowHead = this.drawArrowHead(start, end);
    return new paper.Group(
      {
        children: [arrowTail, arrowHead],
        name: lineSymbol.guid,
        data: {
          type: FigureSymbolType.ARROW,
          dashed: lineSymbol.dashed
        },
      });
  }

  /**
   * Draws arrow head e.g. on movement.
   * @param start The start point of the arrow line.
   * @param end The end point of the arrow line.
   *
   * @returns {paper.Path}
   */
  public drawArrowHead(start: paper.Point, end: paper.Point): paper.Path {
    const tailVector: paper.Point = end.subtract(start);
    const headLine: paper.Point = tailVector.normalize(ARROW_SIZE * this.factor);

    return new paper.Path(
      {
        ...STYLES.ARROW,
        segments: [
          end.add(headLine.rotate(ARROW_ANGLE, new paper.Point(0, 0))),
          end,
          end.add(headLine.rotate(-ARROW_ANGLE, new paper.Point(0, 0))),
        ],
        closed: true,
        locked: true,
        data: {
          type: FigureSymbolSubType.ARROW_HEAD,
          selectionConfig: {
            neverSelect: true
          } as SelectionConfig
        }
      });
  }

  /**
   * Creates a paper.js Group with Paths representing a curly brace.
   * @param symbol The figure symbol as a brace.
   *
   * @returns {paper.Group}
   * @private
   */
  private createBrace(symbol: Brace): paper.Group {
    const start = new paper.Point(symbol.x1 * this.factor, symbol.y1 * this.factor);
    const end = new paper.Point(symbol.x2 * this.factor, symbol.y2 * this.factor); // Default brace size

    // Creates an invisible helpline to simply transformations on the curly brace
    const helpline = new paper.Path.Line(
      {
        ...STYLES.BRACE_HELPLINE,
        strokeWidth: STROKE_WIDTH * this.factor,
        from: start,
        to: end,
        // A color needs to be set, otherwise the object is not selectable (hit is not regognized on
        // transparent line), so we use very very transperent white
        strokeColor: new paper.Color(1, 1, 1, 0.0001),
        data: {type: FigureSymbolSubType.BRACE_HELPLINE},
      });

    const visibleBrace = this.drawBrace(start, end, symbol.braceType, false);
    return new paper.Group(
      {
        children: [helpline, visibleBrace],
        name: symbol.guid,
        data: {
          type: FigureSymbolType.BRACE,
          braceType: symbol.braceType
        },
      });
  }


  /**
   * Draws the curly brace symbol e.g. on movement.
   * @param start The start point of the curly brace.
   * @param end The end point of the curly brace.
   * @param braceType The brace type of the brace to be drawed.
   * @param scale Indicates whether the arrowhead needs to be scaled (it might be already scaled by the group).
   *
   * @returns {paper.Group}
   */
  public drawBrace(start: paper.Point, end: paper.Point, braceType: BraceType, scale: boolean = true): paper.Group {
    const scaledRadius = BRACE_CIRCLE_RADIUS * this.factor; // For scaling
    const vector = end.subtract(start);
    const lineVector: paper.Point = vector.normalize(vector.length / 2);
    const radiusVector = vector.normalize(scaledRadius);
    const braceGroup = this.drawBraceGroup(start, end, radiusVector, lineVector, scaledRadius);

    if (braceType == BraceType.OPEN_BOTTOM) {
      return this.drawOpenBottomBrace(braceGroup, lineVector, vector, start, radiusVector);
    } else if (braceType == BraceType.OPEN_TOP) {
      return this.drawOpenTopBrace(braceGroup, lineVector, vector, start, radiusVector);
    } else {
      return this.drawFullBrace(braceGroup, lineVector);
    }
  }

  /**
   * Compounds the base paper.Group of a Brace.
   * @param start The start point of the brace.
   * @param end The end point of the brace.
   * @param radiusVector The radius vector for the end point of the brace.
   * @param lineVector The line vector to be used for computing the end point of the brace.
   * @param scaledRadius The scaled radius of the brace.
   *
   * @returns {paper.Group}
   * @private
   */
  private drawBraceGroup(start: paper.Point, end: paper.Point, radiusVector: paper.Point,
                         lineVector: paper.Point, scaledRadius: number): paper.Group {
    const angle: number = start.subtract(end).angle;
    const braceLine = new paper.Path.Line(
      {
        ...STYLES.BRACE,
        strokeWidth: STROKE_WIDTH * this.factor,
        from: start.add(radiusVector),
        to: start.add(lineVector).subtract(radiusVector),
        data: {
          selectionConfig: {
            neverSelect: true
          } as SelectionConfig
        },
      });

    const circleCenter = braceLine.segments[0].point.add(radiusVector.rotate(-90, new paper.Point(0, 0)));
    const braceCirle = new paper.Path.Circle(
      {
        ...STYLES.BRACE,
        strokeWidth: STROKE_WIDTH * this.factor,
        center: circleCenter,
        radius: scaledRadius,
        data: {
          selectionConfig: {
            neverSelect: true
          } as SelectionConfig
        },
        locked: true,
      });

    // Removes 3 segments to get a quarter of the circle
    braceCirle.splitAt(braceCirle.length / 2);
    braceCirle.firstSegment.remove();
    braceCirle.firstSegment.remove();
    braceCirle.firstSegment.remove();

    // Rotates quarter of the circle depending on helpline angle
    braceCirle.rotate(angle, circleCenter);

    // Adds second circle quarter the the bottom of the line in different orientation
    const copyCircle = braceCirle.clone();
    copyCircle.rotate(180, start);
    copyCircle.translate(lineVector);

    return new paper.Group(
      {
        children: [braceLine, braceCirle, copyCircle],
        data: {
          selectionConfig: {
            neverSelect: true
          } as SelectionConfig
        },
      });
  }

  /**
   * Compounds a paper.Group object representing a full brace in the canvas.
   * @param braceGroup The brace group to be used as base for compounding the final paper.Group.
   * @param lineVector The line vector to be used for mirroring the base brace paper.Group.
   *
   * @returns {paper.Group}
   * @private
   */
  private drawFullBrace(braceGroup: paper.Group, lineVector: paper.Point): paper.Group {
    // Clones whole group and "mirror" it
    const copyGroup = braceGroup.clone();
    copyGroup.scale(-1, 1); // Mirror
    copyGroup.translate(lineVector); // Moves to the bottom
    copyGroup.rotate(2 * lineVector.angle); // Rotates in opposite direction

    return new paper.Group(
      {
        children: [braceGroup, copyGroup],
        data: {
          selectionConfig: {
            neverSelect: true
          } as SelectionConfig
        },
        locked: true,
      });
  }

  /**
   * Compounds a paper.Group object representing an open bottom brace in the canvas.
   * @param braceGroup The brace group to be used as base for compounding the final paper.Group.
   * @param lineVector The line vector to be used for mirroring the base brace paper.Group.
   * @param vector A vector for normalizing the line of the brace.
   * @param start The start point of the brace.
   * @param radiusVector The vector for normalizing the radius of the brace.
   *
   * @returns {paper.Group}
   * @private
   */
  private drawOpenBottomBrace(braceGroup: paper.Group,
                              lineVector: paper.Point, vector: paper.Point, start: paper.Point, radiusVector: paper.Point): paper.Group {
    const copyGroup = braceGroup.clone();
    copyGroup.scale(-1, 1); // mirror
    copyGroup.translate(lineVector); // Moves to the bottom
    copyGroup.rotate(2 * lineVector.angle); // Rotates in opposite direction
    copyGroup.children[1].remove();

    const halfLine: paper.Point = vector.normalize(vector.length / 4);
    const braceline = new paper.Path.Line(
      {
        ...STYLES.BRACE,
        strokeWidth: STROKE_WIDTH * this.factor,
        from: start.add(radiusVector).add(lineVector),
        to: start.add(radiusVector).add(lineVector).add(halfLine).subtract(radiusVector),
        data: {
          selectionConfig: {
            neverSelect: true
          } as SelectionConfig
        },
      });

    copyGroup.children[0].remove();
    copyGroup.addChild(braceline);
    const dashedBraceLineStart = start.add(radiusVector).add(lineVector).add(halfLine).subtract(radiusVector);
    const dashedBraceline = new paper.Path.Line(
      {
        ...STYLES.BRACE,
        strokeWidth: STROKE_WIDTH * this.factor,
        dashArray: DASHED_LINE,
        from: dashedBraceLineStart,
        to: dashedBraceLineStart.add(radiusVector).add(halfLine),
        data: {
          selectionConfig: {
            neverSelect: true
          } as SelectionConfig
        },
      });
    copyGroup.addChild(dashedBraceline);

    return new paper.Group(
      {
        children: [braceGroup, copyGroup],
        data: {
          selectionConfig: {
            neverSelect: true
          } as SelectionConfig
        },
        locked: true,
      });
  }

  /**
   * Same as #drawOpenBottomBrace() but mirrored.
   */
  private drawOpenTopBrace(braceGroup: paper.Group,
                           lineVector: paper.Point, vector: paper.Point, start: paper.Point, radiusVector: paper.Point): paper.Group {
    const brace = this.drawOpenBottomBrace(braceGroup, lineVector, vector, start, radiusVector);
    brace.scale(-1, 1);
    brace.rotate(2 * lineVector.angle);
    return brace;
  }

  /**
   * Adds the help lines delimiting the droppable area to the current project.
   */
  private addHelpLines(project: paper.Project): void {
    const helpLinesLayer = new paper.Layer({insert: false});
    helpLinesLayer.name = LayerName.HELP_LINES;
    project.addLayer(helpLinesLayer);

    helpLinesLayer.activate();
    helpLinesLayer.addChild(new paper.Path.Rectangle(
      {
        ...STYLES.HELP_LINES,
        from: new paper.Point(this.offset, this.offset),
        to: new paper.Point(
          this.project.view.bounds.width - this.offset + STYLES.HELP_LINES.strokeWidth,
          this.project.view.bounds.height - this.offset + STYLES.HELP_LINES.strokeWidth),
      }));
  }

  /**
   * Adds a white background (needed when rendering and image)
   */
  private addWhiteBackground(): void {
    const rect = new paper.Path.Rectangle(
      {
        point: [0, 0],
        size: [this.project.view.size.width, this.project.view.size.height],
        strokeColor: 'white',
      });

    rect.sendToBack();
    rect.fillColor = new paper.Color('#ffffff');
  }

  /**
   * Internal method for selecting or unselecting an item and its children.
   * Items can use special selection features that can be configured by SelectionConfig (look for documentation there!).
   */
  public selectItem(item: paper.Item, selected: boolean): void {
    item.selected = selected;
    const selectionConfig = item.data.selectionConfig as SelectionConfig;
    if (selectionConfig) {
      if (selectionConfig.neverSelect) {
        // Never select this item.
        item.selected = false;
      }
      if (selectionConfig.fullySelectedAutomatically && item.className === "Path" && selected) {
        // If this is a selected path, switch on fullySelected.
        (item as paper.Path).fullySelected = true;
      }
      if (selectionConfig.colorForCustomSelectionMarker) {
        // Don't use normal "selected" property, because this would enable the handles,
        // instead we change the strokeColor from transparent to the configured custom selection color.
        item.selected = false;
        const color = selected ? selectionConfig.colorForCustomSelectionMarker : TRANSPARENT_COLOR;
        item.strokeColor = new paper.Color(color);
      }
      if (selectionConfig.colorForCustomSelectionHelpLine) {
        // Don't use normal "selected" property, because this would enable the handles,
        // instead we change the strokeColor from transparent to the configured custom selection color.
        item.selected = false;
        const color = selected ? selectionConfig.colorForCustomSelectionHelpLine : STYLES.USER_HELP_LINES.strokeColor;
        item.strokeColor = new paper.Color(color);
      }
    }
    // continue recursively
    item.children?.forEach(child => this.selectItem(child, selected));
  }

  /**
   * Clears resources in project.
   */
  public clear(): void {
    if (paper.project.view) {
      paper.project.view.remove();
    }
    paper.project.clear();
    paper.project.remove();
  }
}


